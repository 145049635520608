import React, { createContext, useContext, useReducer } from 'react'

const TokenStateContext = createContext()
const TokenDispatchContext = createContext()

function tokenReducer(state, action) {
  switch (action.type) {
    case 'set': {
      return {
        auth: { name: action.auth.name, token: action.auth.token },
        confirmation: { token: action.confirmation.token },
        requestPassword: { token: action.requestPassword.token },
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function TokenProvider({ children }) {
  const [state, dispatch] = useReducer(tokenReducer, {
    auth: { name: 'authenticity_token', token: '' },
    confirmation: { token: '' },
    requestPassword: { token: '' },
  })
  return (
    <TokenStateContext.Provider value={state}>
      <TokenDispatchContext.Provider value={dispatch}>{children}</TokenDispatchContext.Provider>
    </TokenStateContext.Provider>
  )
}

function useTokenState() {
  const context = useContext(TokenStateContext)
  if (context === undefined) {
    throw new Error('useTokenState must be used within a TokenProvider')
  }
  return context
}

function useTokenDispatch() {
  const context = React.useContext(TokenDispatchContext)
  if (context === undefined) {
    throw new Error('useTokenDispatch must be used within a TokenProvider')
  }
  return context
}

export { TokenDispatchContext, TokenProvider, useTokenState, useTokenDispatch }
