import React, { createContext, useContext, useReducer } from 'react'

const MessageStateContext = createContext()
const MessageDispatchContext = createContext()

function messageReducer(state, action) {
  switch (action.type) {
    case 'set': {
      return {
        errors: action.errors,
        messages: action.messages,
      }
    }
    case 'clear': {
      return {
        errors: [],
        messages: [],
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function MessageProvider({ children }) {
  const [state, dispatch] = useReducer(messageReducer, {
    errors: [],
    messages: [],
  })
  return (
    <MessageStateContext.Provider value={state}>
      <MessageDispatchContext.Provider value={dispatch}>{children}</MessageDispatchContext.Provider>
    </MessageStateContext.Provider>
  )
}

function useMessageState() {
  const context = useContext(MessageStateContext)
  if (context === undefined) {
    throw new Error('useMessageState must be used within a MessageProvider')
  }
  return context
}

function useMessageDispatch() {
  const context = React.useContext(MessageDispatchContext)
  if (context === undefined) {
    throw new Error('useMessageDispatch must be used within a MessageProvider')
  }
  return context
}

export { MessageDispatchContext, MessageProvider, useMessageState, useMessageDispatch }
