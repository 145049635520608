import React, { useCallback, useMemo, useReducer } from 'react'
import {
  Button,
  Checkbox,
  Element,
  FullscreenPage,
  Group,
  HiddenInput,
  Icon,
  Input,
  SwitchView,
  theme,
} from '@fortressiq/fiq-ds'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'

import api from 'lib/Api'

import { useTokenState } from '../TokenContext'
import { useMessageState } from '../MessageContext'
import loginFooter from '../common/loginFooter'

export default () => {
  const {
    auth: { name, token },
  } = useTokenState()
  const { errors, messages } = useMessageState()
  const [formState, dispatch] = useReducer(
    (state, { type, payload }) => ({
      ...state,
      [type]: payload,
    }),
    {
      password: '',
      username: '',
      ssoLink: null,
    }
  )

  const handleUsernameChange = e => {
    dispatch({ type: 'username', payload: e.target.value })
    debouncedSAMLRequest(e?.target?.value)
  }
  const handlePasswordChange = e => dispatch({ type: 'password', payload: e.target.value })

  const checkSAMLUser = useCallback(async u => {
    const { data } = await api.get(`/api/v1/users/info?username=${encodeURIComponent(u)}`, null, false)

    if (data?.provider === 'saml') {
      dispatch({ type: 'ssoLink', payload: data.redirectURI })
    } else {
      dispatch({ type: 'ssoLink', payload: null })
    }
  }, [])

  const debouncedSAMLRequest = useMemo(() => debounce(checkSAMLUser, 500), [checkSAMLUser])

  const showForgotP = formState?.ssoLink === null

  const pageContent = [
    <Input
      block={true}
      key='username'
      name='user[username]'
      onChange={handleUsernameChange}
      placeholder='Username'
      prefixIcon='fte'
      type='text'
      value={formState?.username}
    />,
    <SwitchView
      defaultView={
        <Input
          autoComplete='off'
          block={true}
          name='user[password]'
          onChange={handlePasswordChange}
          placeholder='Password'
          prefixIcon='keyhole'
          type='password'
          value={formState?.password}
        />
      }
      key='switchview'
      switchTo={
        <Element
          style={{
            alignItems: 'center',
            display: 'flex',
            height: theme['default-spacer-lg'],
            justifyContent: 'center',
          }}
        >
          <Icon icon='loginlock' style={{ marginRight: theme['default-spacer-sm'] }} />
          Single Sign-on Enabled
        </Element>
      }
      switchView={formState?.ssoLink !== null}
    />,
    <Button block={true} htmlType='submit' key='signinbtn' name='commit' type='primary'>
      Sign In
    </Button>,
    <Group
      {...(showForgotP && {
        colStyles: {
          width: '50%',
          '&:last-child': {
            justifyContent: 'flex-end',
          },
        },
      })}
      gutter={0}
      key='options'
      noEndGutter={true}
      noOfCols={showForgotP ? 2 : 1}
      type='flex'
    >
      <Checkbox name='user[remember_me]' value='1'>
        Remember Me
      </Checkbox>
      {showForgotP && <Link to='/users/password/new'>Forgot your password?</Link>}
    </Group>,
  ]
  const formSettings = {
    acceptCharset: 'UTF-8',
    action: formState?.ssoLink || '/users/sign_in',
    hiddenFields: (
      <HiddenInput
        fields={[
          { name: 'uft8', value: '✓' },
          { name: name, value: token },
          { name: 'user[remember_me]', value: '0' },
        ]}
      />
    ),
    method: 'post',
  }

  return (
    <FullscreenPage
      content={pageContent}
      errors={errors}
      footer={loginFooter()}
      form={formSettings}
      messages={messages}
      title='Sign in to your account.'
    />
  )
}
