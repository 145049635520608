import React, { Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory } from 'react-router-dom'

import LoadingFallback from 'components/loaders/MainLoader'

import { useMessageDispatch } from './MessageContext'
import Login from './login'

const Routes = ({ confirmationToken, requestPasswordToken }) => {
  const routes = [
    { path: '/users/password/new', component: () => import('./forgot_password') },
    { path: '/users/password/edit', component: () => import('./password_reset') },
    {
      path: '/users/password',
      component: () => {
        if (requestPasswordToken) return import('./password_reset')
        else return import('./forgot_password')
      },
    },
    { path: '/users/confirmation/new', component: () => import('./confirmation_request') },
    {
      path: '/users/confirmation',
      component: () => {
        if (confirmationToken) return import('./confirmation')
        else return import('./confirmation_request')
      },
    },
    { path: '/user/confirmation', component: () => import('./confirmation') },
  ]

  const history = useHistory()
  const messageDispatch = useMessageDispatch()

  history.listen(() => {
    // if the user changes location, clear out the errors and messages
    messageDispatch({ type: 'clear' })
  })

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Switch>
        {routes.map(({ component, routes: nestedRoutes, switchRoutes, exact, ...rest }, index) => {
          const Component = lazy(component)
          const key = `${rest.path.replace('/', '-')}-${index}`
          return <Route exact={exact} render={props => <Component {...props} {...rest} />} key={key} {...rest} />
        })}
        <Route component={Login} />
      </Switch>
    </Suspense>
  )
}

Routes.propTypes = {
  confirmationToken: PropTypes.string,
  lastMethod: PropTypes.string,
  requestPasswordToken: PropTypes.string,
}

Routes.defaultProps = {
  confirmationToken: '',
  lastMethod: '',
  requestPasswordToken: '',
}

export default Routes
