import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ModalProvider, theme, ThemeProvider } from '@fortressiq/fiq-ds'

import Routes from './Routes'
import { useTokenDispatch, TokenProvider } from './TokenContext'
import { useMessageDispatch, MessageProvider } from './MessageContext'

function Base() {
  const tokenDispatch = useTokenDispatch()
  const messageDispatch = useMessageDispatch()
  const [requestPasswordToken, setRequestPasswordToken] = useState('')
  const [confirmationToken, setConfirmationToken] = useState('')

  useEffect(() => {
    const authTokenElement = document.getElementById('fiq:authenticity_token')
    const authInfo = JSON.parse(authTokenElement.content)

    const confirmationTokenEl = document.getElementById('fiq:confirmation_token')
    setConfirmationToken(confirmationTokenEl.content)

    const requestPasswordTokenEl = document.getElementById('fiq:reset_password_token')
    setRequestPasswordToken(requestPasswordTokenEl.content)

    tokenDispatch({
      type: 'set',
      auth: {
        name: authInfo.name,
        token: authInfo.token,
      },
      confirmation: {
        token: confirmationTokenEl.content,
      },
      requestPassword: {
        token: requestPasswordTokenEl.content,
      },
    })
  }, [tokenDispatch])

  useEffect(() => {
    const errorsEl = document.getElementById('fiq:errors')
    const errorsObj = JSON.parse(errorsEl.content)
    const messagesEl = document.getElementById('fiq:messages')
    const messagesObj = JSON.parse(messagesEl.content)
    let messageKeys = Object.keys(messagesObj)

    // extract any alert messages and put them in errors
    const errors = errorsObj ? errorsObj.errors.map(error => ({ text: error })) : []

    messageKeys = messageKeys.reduce((acc, key) => {
      if (key === 'alert') {
        errors.push({ text: messagesObj[key] })
      } else {
        acc.push(key)
      }
      return acc
    }, [])

    const messages = messageKeys
      ? messageKeys.map(key => ({
          text: messagesObj[key],
        }))
      : []

    messageDispatch({
      type: 'set',
      errors,
      messages,
    })
  }, [messageDispatch])

  return (
    <Router>
      <Routes confirmationToken={confirmationToken} requestPasswordToken={requestPasswordToken} />
    </Router>
  )
}

export default () => {
  return (
    <TokenProvider>
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <MessageProvider>
            <Base />
          </MessageProvider>
        </ModalProvider>
      </ThemeProvider>
    </TokenProvider>
  )
}
